@import 'src/client/application/variables.sass'
@import '@ahmdigital/ui/src/utils/constants/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/rem.module.sass'
.hideDesktop
  display: block

  +above($screen-lg)
    display: none

.hideTabletAndMobile
  display: none

  +above($screen-lg)
    align-items: center
    display: flex
    justify-content: center

