@import 'src/client/application/variables.sass'
@import '@ahmdigital/ui/src/utils/constants/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/rem.module.sass'
.imageWrapper
  align-self: center
  text-align: center

.image
  @include below($screen-lg)
    max-height: 18rem
