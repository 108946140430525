@import 'src/client/application/variables.sass'
@import '@ahmdigital/ui/src/utils/constants/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/rem.module.sass'
.section
  padding: 1rem

.horizontalSpacingWrapper
  margin: auto
  max-width: 66rem

.invert
  color: $color-white

.wide
  max-width: 84rem
