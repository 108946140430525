@import 'src/client/application/variables.sass'
@import '@ahmdigital/ui/src/utils/constants/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/rem.module.sass'
.copyWrapper
  align-self: center

.ctaWrapper
  +above($screen-xs)
    display: flex

.ctaButtonWrapper
  padding-top: 0.5rem

  +above($screen-xs)
    padding-left: 0.5rem
    padding-top: 1.55rem

.ctaButton
  font-size: 1.125rem
  padding: 0.75rem 2rem

.restrictedWidthDesktop
  +above($screen-xs)
    width: 20rem

.section
  +above($screen-sm)
    padding-left: 2rem
    padding-right: 2rem

.headerSection
  padding-left: 1rem
  padding-right: 1rem

  +above($screen-lg)
    padding-left: 2rem
    padding-right: 2rem
