@import 'src/client/application/variables.sass'
@import '@ahmdigital/ui/src/utils/constants/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/rem.module.sass'
.imageWrapper
  margin-bottom: 2.5rem
  text-align: center

  +above($screen-md)
    margin-bottom: 0

.image
  max-height: 10rem

  +above($screen-sm)
    max-height: 13rem

  +above($screen-md)
    max-height: 14rem

.section
  +above($screen-sm)
    padding-left: 2rem
    padding-right: 2rem
