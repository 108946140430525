@import 'src/client/application/variables.sass'
@import '@ahmdigital/ui/src/utils/constants/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/media.module.sass'
@import '@ahmdigital/ui/src/utils/mixins/rem.module.sass'
.image
  display: flex
  height: 11rem
  margin: 0 auto 1rem

.wrapper
  padding: 1rem

.lifeStageTileWrapper
  @include above($screen-md)
    padding: 1rem

.lifeStageContentWrapper
  @include below($screen-md)
    display: none

.lifeStageButton
  font-size: 1rem
  margin-bottom: 0.5rem
  max-width: 22.5rem
  text-align: left
  width: 100%

  @include above($screen-md)
    margin-top: 1.5rem
    text-align: center
